var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":_vm.company,"left-text":"","left-arrow":""},on:{"click-left":_vm.goBack,"click-right":_vm.gohome2},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-icon',{attrs:{"name":"wap-home-o","size":"18"}})]},proxy:true}])}),_c('div',{staticClass:"worthtest"},[_vm._l((_vm.subjectList),function(item,index){return (_vm.num == index && _vm.num !== _vm.subjectList.length)?_c('div',{key:index,staticClass:"paading-min"},[_c('div',[_vm._v(_vm._s(_vm.num + 1)+" / "+_vm._s(_vm.subjectList.length + 3))]),_c('div',{staticClass:"xhtm"},[_c('div',{staticClass:"timu"},[_vm._v(_vm._s(item.subject))]),_vm._l((item.item),function(option,optionIndex){return _c('div',{key:optionIndex,staticClass:"xuanxiangcenter"},[_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive: option.active,
              disabled: option.disabled,
            },on:{"click":function($event){return _vm.handleOptionClick(item, option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])])})],2)]):_vm._e()}),(_vm.num == 101)?_c('div',{staticClass:"paading-min"},[_c('div',[_vm._v("102 / "+_vm._s(_vm.subjectList.length + 3))]),_c('div',{staticClass:"xhtm"},[_c('div',{staticClass:"timu"},[_vm._v(" 您对"+_vm._s(_vm.company)+"的满意度是多少？（1分-很不满意，5分-非常满意） ")]),_c('div',{staticClass:"myd"}),_c('div',{staticClass:"fs"},[_c('div',[_vm._v("不满意")]),_c('van-rate',{attrs:{"void-color":"#B9B8B8","color":"#72ACF4","size":"26"},model:{value:(_vm.fs),callback:function ($$v) {_vm.fs=$$v},expression:"fs"}}),_c('div',[_vm._v("非常满意")])],1)])]):_vm._e(),(_vm.num == 102)?_c('div',{staticClass:"paading-min"},[_c('div',[_vm._v("103 / 104")]),_c('div',{staticClass:"xhtm"},[_c('div',{staticClass:"timu"},[_vm._v(" 如果以后还有类似的"+_vm._s(_vm.company)+"活动，您有多大意愿参加？（1分-很不愿意，5分-非常愿意） ")]),_c('div',{staticClass:"fs"},[_c('div',[_vm._v("不满意")]),_c('van-rate',{attrs:{"void-color":"#B9B8B8","color":"#72ACF4","size":"26"},model:{value:(_vm.fs2),callback:function ($$v) {_vm.fs2=$$v},expression:"fs2"}}),_c('div',[_vm._v("非常满意")])],1)])]):_vm._e(),(_vm.num === 103)?_c('div',{staticClass:"paading-min"},[_c('div',[_vm._v("104 / 104")]),_c('div',{staticClass:"xhtm"},[_c('div',{staticClass:"timu"},[_vm._v("您对以下哪方面的训练内容更有兴趣？（可多选）")]),_c('div',{staticClass:"xuanxiangcenter"},[_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive: _vm.selectedOptions.includes('睡眠相关主题的练习'),
            },on:{"click":function($event){return _vm.toggleOption('睡眠相关主题的练习')}}},[_vm._v(" 睡眠相关主题的练习 ")]),_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive:
                _vm.selectedOptions.includes('专注力相关主题的练习'),
            },on:{"click":function($event){return _vm.toggleOption('专注力相关主题的练习')}}},[_vm._v(" 专注力相关主题的练习 ")]),_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive:
                _vm.selectedOptions.includes('亲子沟通相关主题的练习'),
            },on:{"click":function($event){return _vm.toggleOption('亲子沟通相关主题的练习')}}},[_vm._v(" 亲子沟通相关主题的练习 ")]),_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive: _vm.selectedOptions.includes('正念减压相关练习'),
            },on:{"click":function($event){return _vm.toggleOption('正念减压相关练习')}}},[_vm._v(" 正念减压相关练习 ")]),_c('div',{staticClass:"xuanxiang",class:{
              xuanxiangactive: _vm.selectedOptions.includes('运动健康相关练习'),
            },on:{"click":function($event){return _vm.toggleOption('运动健康相关练习')}}},[_vm._v(" 运动健康相关练习 ")])])]),_c('div',{staticClass:"qita"},[_c('div',{staticClass:"qitatitle"},[_vm._v("其他")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.qita),expression:"qita"}],staticClass:"textarea",attrs:{"placeholder":"请输入您的提议请输入您的提议请输入您的提议请输入您的提议","name":"","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.qita)},on:{"input":function($event){if($event.target.composing){ return; }_vm.qita=$event.target.value}}})])]):_vm._e(),_c('div',{staticClass:"btn-center"},[(_vm.num > 0)?_c('button',{staticClass:"shangti",on:{"click":_vm.uptimu}},[_vm._v("上一题")]):_vm._e(),(_vm.num == 101 && _vm.fs)?_c('button',{staticClass:"shangti",on:{"click":_vm.gotimu}},[_vm._v(" 下一题 ")]):_vm._e(),(_vm.num == 102 && _vm.fs2)?_c('button',{staticClass:"shangti",on:{"click":_vm.gotimu}},[_vm._v(" 下一题 ")]):_vm._e(),(_vm.num == 103)?_c('button',{staticClass:"shangti2",on:{"click":_vm.submit}},[_vm._v("提交")]):_vm._e()])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showtanchuang),expression:"showtanchuang"}],staticClass:"tanchuang"},[_c('div',{staticClass:"tanchuanghezi"},[_c('div',[_vm._v("提交成功")]),_c('button',{on:{"click":_vm.totestresult}},[_vm._v("查看结果")]),_c('button',{on:{"click":function($event){return _vm.gohome()}}},[_vm._v("返回主页")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }